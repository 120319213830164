import { Container, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import React, { Fragment } from 'react';
import CookieBar from '../components/Controls/CookieBar';
import Section from '../components/Controls/Section';
import Footer from '../components/Footer';
import HomepageHeader from '../components/HomepageHeader';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            minHeight: '100vh',
            '& ul li': {
                margin: theme.spacing(2, 0),
            },
        },
    }),
);

const NHS = () => {
    const classes = useStyles();
    return (
        <Fragment>
            <HomepageHeader />
            <Container maxWidth="md" className={classes.container}>
                <Section sectionTitle="NHS worker £50 cashback terms and conditions. How to get your £50 cashback.">
                    <ul>
                        <li>
                            <Typography>
                                Purchase a Uinsure Home Insurance or Buy to Let Insurance policy by
                                30th June 2020.
                            </Typography>
                        </li>
                        <li>
                            <Typography>
                                Make your first two regular monthly payments by Direct Debit. The
                                £50 cashback will be paid into the same bank account as your Direct
                                Debit within 30 days of your second payment.
                            </Typography>
                        </li>
                        <li>
                            <Typography>
                                If you pay for your policy in one annual payment you will receive
                                the £50 cashback within 60 days of the start date.
                            </Typography>
                        </li>
                    </ul>
                </Section>
                <Section sectionTitle="What else you need to know:">
                    <ul>
                        <li>
                            <Typography>
                                You must send us confirmation that you are an NHS worker (e.g. a
                                picture of your NHS card) together with your policy number to claim
                                your cash back to nhscashback@uinsure.co.uk .
                            </Typography>
                        </li>
                        <li>
                            <Typography>
                                The £50 cashback payment will not count towards your monthly
                                premiums.
                            </Typography>
                        </li>
                        <li>
                            <Typography>
                                Cashback won’t be paid if you cancel the Uinsure policy within 60
                                days of the start date.
                            </Typography>
                        </li>
                        <li>
                            <Typography>Only 1 payment of £50 will be paid per policy .</Typography>
                        </li>
                        <li>
                            <Typography>We can withdraw this offer at any time.</Typography>
                        </li>
                    </ul>
                </Section>
            </Container>
            <Footer />
            <CookieBar />
        </Fragment>
    );
};

export default NHS;
